<template>
  <v-row justify="center">
    <!-- Old Version -->
    <!-- <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("trashPage.detaildelete") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list dense tile>
            <v-list-item
              v-for="(item, i) in showdatadelete"
              :key="i"
              v-show="item.backup_status.status === 'ER'"
              dense
              three-line
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.file_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.backup_status.message }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.value"
                  size="40"
                  color="red"
                  >
                  <div>{{ item.backup_status.value }}</div>
                </v-progress-circular>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="$emit('closedialog')"
            >{{ $t("dragdrop.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->





    <!-- <v-dialog
      v-model="shower"
      v-else
      persistent
      scrollable
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 text-center">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-4">
              <span :style="headerPageDialog">{{ $t("trashPage.detaildelete") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-list v-for="(item, i) in showdatadelete" :key="i" v-show="item.backup_status.status === 'ER'">
            <v-list-item dense two-line>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.backup_status.value"
                  size="30"
                  color="red"
                >
                  {{ item.backup_status.value }}
                </v-progress-circular>
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-list-item-title :style="titleText">{{
                  item.file_name
                }}</v-list-item-title>
                <v-list-item-title :style="titleText">{{
                 item.backup_status.message
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="text-center mt-6">
            <v-btn
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="90%"
              color="red"
              outlined
              @click="$emit('closedialog')"
              >{{ $t("changeName.close") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    
    <!-- New Version -->
    <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("trashPage.detaildelete") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
           <div class="text-right">
            <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ count_folder }} {{$t("multirecovery.folder")}}</b></p></v-chip>
            <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> {{ count_file }} {{$t("multirecovery.file")}}</b></p></v-chip>
          </div>
          <v-list class="list-item">
            <!--โชว์ไฟล์สามารถลบได้ -->
            <v-layout align-center justify-center v-if="loadingdata && this.type === 'CT'" >
              <v-progress-circular
                :size="40"
                :color="color.theme"
                indeterminate
              ></v-progress-circular>
            </v-layout>
            <v-list-item v-for="item in showrestore" :key="item.file_id" v-else>
              <v-icon @mouseover="item.show_name = true" @mouseleave="item.show_name = false" large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
              <v-badge
                      :value="item.show_name"
                      color="grey"
                      :content="item.file_name"
                      transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                <v-list-item-title class="ml-4" @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}
                </v-list-item-title>
                <v-list-item-subtitle class="ml-4" >
                  <v-progress-linear
                          v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                          v-show="item.permission_account_v2 !== '05' || '06'"
                          :value="item.file_value"
                          color="light-blue"
                  ></v-progress-linear>
                  <span v-else>
                    {{ item.file_status === $t("dialogmultidelete.deletesuccess") ? '' : item.file_status_message }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action >
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" 
                      :color="
                      item.file_status_pregress === '' ? color.theme : 
                      item.file_status_pregress === 'OK' ? 'green' : 'red'" label outlined>
                        {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="$emit('closedialog')"
            >{{ $t("dragdrop.close") }}</v-btn
          >
          <v-btn
            v-if="check_show_progress === false && check_show_button === false && checkPermissionAll === false && !loadingdata "
            color="red darken-1 white--text"
            depressed
            @click="fn_multipermanent_remove_v2(), (check_show_button = true), (check_show_progress = true)"
            >{{ $t("trashPage.forcedelete") }}</v-btn
          >

            <!-- CANCEL  -->
            <v-btn color="red" depressed :dark="color.darkTheme"  v-if="check_show_cancel"
            @click="cancelDelete()"> {{ $t('dialogmultidelete.deletecancel') }}</v-btn>
           <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
           <v-btn color="red  darken-1 white--text" 
           :disabled="check_dis_button" 
           v-if="check_show_button === true" 
           depressed 
           @click="clearalldata">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { CancelToken } from "axios";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "showdatadelete", "type", "recent_offset"],
  data: function() {
    return {
      loadingdata: false,
      listdataupload: [],
      fileUpload: [],
      showMenu: false,
      showitem: false,
      x: 0,
      y: 0,
      
      //New variables
      cancelSource: "",
      showrestore: [],
      count_folder:0,
      count_file:0,
      check_dis_button:true,
      check_show_progress: false,
      check_show_button: false,
      check_show_cancel: false,
      check_cancel_token: false,
      checkPermissionAll: false,
      tab: 0
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },

  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 25) return string.substring(0, 25) + "...";
        else return string;
      }
    },
  },

  watch: {
    show: function(val) {
      if (val === true) {
        if(this.type === 'MT'){ // multi delete ทำดา
        //Add Mai
        this.fn_loaddata();
        this.check_show_button = false;
        } else {  // clear trash
          this.loadingdata = true
          this.check_dis_button = true
          this.fn_loaddata_v2()
          this.check_show_button = false;
         }
      } else {
        //Add Mai
        this.check_show_progress = false;
      }
    },
  },
  methods: { // เพิ่ม method ใหม่ทั้งหมด
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    fn_loaddata() {
      this.showrestore = [];
      this.count_folder = 0;
      this.count_file = 0;
      this.check_dis_button = true
      for (let i = 0; i < this.showdatadelete.length; i++) {
        if(this.showdatadelete[i].file_type === 'folder'){
          this.count_folder++;
        }else{
          this.count_file++;
        }
        let datarestore = {};
        datarestore["file_name"] = this.showdatadelete[i]["file_name"];
        datarestore["file_id"] = this.showdatadelete[i]["file_id"];
        datarestore["file_icon"] = this.showdatadelete[i]["file_icon"];
        datarestore["file_status"] = this.showdatadelete[i]["permission_account_v2"] === '05' || this.showdatadelete[i]["permission_account_v2"] === '06' ? this.$t("dialogmultidelete.canbedelete") : this.$t("dialogmultidelete.cannotdelete") ;
        datarestore["file_status_message"] = ""
        datarestore["file_status_pregress"] =this.showdatadelete[i]["permission_account_v2"] === '05' || this.showdatadelete[i]["permission_account_v2"] === '06' ? '' : 'ERR'
        datarestore["file_permission"] = this.showdatadelete[i]["permission_account_v2"];
        datarestore["trash_dtm"] = this.showdatadelete[i]["trash_dtm"] 
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.showdatadelete[i]["file_type"];
        datarestore["file_createdtm"] = this.showdatadelete[i]["file_createdtm"] 
        datarestore["full_path"] = this.showdatadelete[i]["full_path"]
          this.showrestore.push(datarestore);
        }

        if(this.showdatadelete.map((item) => item.permission_account_v2).filter((permis) => permis  === '05' || permis === '06').length > 0){
        this.checkPermissionAll = false
        }
        else {
          this.checkPermissionAll = true
        }
        
        
    },
    async fn_loaddata_v2() { // loaddata_v2 ไว้ใช้สำหรับ วนลูปยิง api ข้อมูลทั้งหมด
      this.showrestore = [];
      this.count_folder = this.recent_offset.all_folder;
      this.count_file = this.recent_offset.all_file;
      var sumdata = this.count_file + this.count_folder
      for (let i = 0; i < this.showdatadelete.length; i++) {
        let datarestore = {};
        datarestore["file_name"] = this.showdatadelete[i]["file_name"];
        datarestore["file_id"] = this.showdatadelete[i]["file_id"];
        datarestore["file_icon"] = this.showdatadelete[i]["file_icon"];
        datarestore["file_status"] = this.showdatadelete[i]["permission_account_v2"] === '05' || this.showdatadelete[i]["permission_account_v2"] === '06' ? this.$t("dialogmultidelete.canbedelete") : this.$t("dialogmultidelete.cannotdelete") ;
        datarestore["file_status_message"] = ""
        datarestore["file_status_pregress"] =this.showdatadelete[i]["permission_account_v2"] === '05' || this.showdatadelete[i]["permission_account_v2"] === '06' ? '' : 'ERR'
        datarestore["file_permission"] = this.showdatadelete[i]["permission_account_v2"];
        datarestore["trash_dtm"] = this.showdatadelete[i]["trash_dtm"] 
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.showdatadelete[i]["file_type"];
        datarestore["file_createdtm"] = this.showdatadelete[i]["file_createdtm"] 
        datarestore["full_path"] = this.showdatadelete[i]["full_path"]
          this.showrestore.push(datarestore);
        }
      // 60
      // 0 -> 20  = (60-20) 
      for(let round = 1; round <= Math.ceil((sumdata - (this.recent_offset.offset + this.recent_offset.limit )) / 20) ; round++){
        let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        status_file: "T",
        status_folder: "T",
        limit: this.recent_offset.limit,
        offset: this.recent_offset.offset + (20 * round),
        sort_by: this.recent_offset.sort_by,
        direction: this.recent_offset.direction,
      };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder_trash", // then "/api/select_files_and_folder"
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then( async (response) => {
            for(let i = 0; i < response.data.data.length; i++){
            let datarestore = {};
            datarestore["file_name"] = response.data.data[i].name;
            datarestore["file_id"] = response.data.data[i].id;
            datarestore["file_icon"] = this.seticon_New(response.data.data[i].type, response.data.data[i].type) //
            datarestore["file_status"] = response.data.data[i].permission_account_v2 === '05' || response.data.data[i].permission_account_v2 === '06' ? this.$t("dialogmultidelete.canbedelete") : this.$t("dialogmultidelete.cannotdelete") ;
            datarestore["file_status_message"] = ""
            datarestore["file_status_pregress"] = response.data.data[i].permission_account_v2 === '05' || response.data.data[i].permission_account_v2 === '06' ? '' : 'ERR'
            datarestore["file_permission"] = response.data.data[i].permission_account_v2;
            datarestore["trash_dtm"] = response.data.data[i].trash_dtm;
            datarestore["file_value"] = 0;
            datarestore["show_name"] = false;
            datarestore["file_type"] = response.data.data[i].type === "folder" ? "folder" : response.data.data[i].type;
            datarestore["file_createdtm"] = response.data.data[i].cre_dtm;
            datarestore["full_path"] = response.data.data[i].full_path
            this.showrestore.push(datarestore);
            }

          })
        
        }

      
      this.loadingdata = false
      if(this.showdatadelete.map((item) => item.permission_account_v2).filter((permis) => permis  === '05' || permis === '06').length > 0){
      this.checkPermissionAll = false
      }
      else {
        this.checkPermissionAll = true
      }
        
        
    },
    // ตั้ง icon file/folder
    seticon_New(type, parameter) {  // เพิ่มมาไว้ set ของ data ที่เพิ่งยิง api ในการขอ clear trash
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      console.log(parameter);
      if (type == "folder") {
        // dataicon = ["folder",
        // // "blue"
        //  "#FDD361"
        // ];
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          // dataicon = ["mdi-file-excel", "green"];
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          // dataicon = ["mdi-file-powerpoint", "orange"];
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          // dataicon = ["mdi-file-word", "primary"];
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          // dataicon = ["mdi-file-pdf", "red"];
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif"
        ) {
          // dataicon = [
          //   "mdi-file-image",
          //   "blue-grey",
          //   // "#F6AD01",
          // ];
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }

      return dataicon;
    },
    fn_scrollToItem (item) {
      item.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' })
    },

    // ## จัด group ไฟลโฟลเดอ
    async file_id_group (file_id, per_chunck) { 
    const result = file_id.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/per_chunck)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
      }, [])

     return result
    },

    // ## จัด Element node treeview ตาม group ไฟลโฟลเดอ
    async slice_node_treeview_chunck(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          res.push(chunk);
      }
      return res;
    },

    async fn_multipermanent_remove() { // ไม่ได้ใช้งาน
      for(let i = 0; i < this.showrestore.length; i++){
          if(this.showrestore[i]["file_permission"] === '05' || this.showrestore[i]["file_permission"] === '06'){
            this.showrestore[i]["file_status"] = this.$t('dialogmultidelete.deleting');
            let payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              data_id: this.showrestore[i]["file_id"],
              data_type: this.showrestore[i]["file_type"] === "folder" ? "folder" : "file",
            };
            await this.fn_remove(payload, this.showrestore[i].file_id)
            .then(async (res) => {
              // console.log("res++++",res);
              if(res.status === "remove success"){
                this.showrestore[i]["file_status"] = this.$t('dialogmultidelete.deletesuccess');
                this.showrestore[i]["file_value"] = 100;
                this.showrestore[i]["file_status_message"] = ""
                this.showrestore[i]["file_status_pregress"] = 'OK'
                console.log("yess ",this.success_rocovery);
              }
              else{
                this.showrestore[i]["file_status"] = this.$t('dialogmultidelete.deletefail');
                this.showrestore[i]["file_value"] = 500;
                this.showrestore[i]["file_status_pregress"] = 'ERR'
                // this.nonesuccess_recovery.push(this.showrestore[i])
              }
            });
          }
          else {
            //this.showrestore[i]["file_status"] = this.$t("multirecovery.notallowpermission");
            this.showrestore[i]["file_status_pregress"] = 'ERR'
            this.showrestore[i]["file_value"] = 100;
          }

          
          this.fn_scrollToItem(document.getElementsByClassName('list-item')[0].children[i])
          if(this.showrestore.length - i === 1){
              this.check_dis_button = false;
          }
      }
     
    },

    async fn_multipermanent_remove_v2 () { //ใช้งานอยู่
      this.check_show_cancel = true
      let chunck_group_array = this.showrestore.length <= 1 ?
      [this.showrestore] : await this.file_id_group(this.showrestore.filter((item)=> item.file_permission === '05' || item.file_permission === '06' ), 5)
      for(var i = 0; i < chunck_group_array.length; i++ ){
        let check_error_round = false
        var check_cancel_error = false
        this.cancelSource = CancelToken.source()
        const promise = await chunck_group_array[i].map(async (item) => {
          item.file_status = this.$t('dialogmultidelete.deleting');
          let payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              data_id: item.file_id,
              data_type: item.file_type === "folder" ? "folder" : "file",
            };
          const responseDelete = await this.fn_remove_v2(payload, item.file_id)
          return responseDelete
        })

        await Promise.all(promise)
        .then( async (AllRes) => {
          for(const itemFile of AllRes){
            let findIndexItem = this.showrestore.findIndex((item) => item.file_id === itemFile.id)
            if(itemFile.status === 'OK'){
              this.showrestore[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletesuccess');
              this.showrestore[findIndexItem]["file_value"] = 100;
              this.showrestore[findIndexItem]["file_status_pregress"] = 'OK'
            } else if (itemFile.status === 'FILE/FOLDER ERROR') {
              this.showrestore[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletefail');
              this.showrestore[findIndexItem]["file_value"] = 100;
              this.showrestore[findIndexItem]["file_status_pregress"] = 'ERR'
            } else if (itemFile.status === 'AXIOS ERROR') {
              this.showrestore[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletefail');
              this.showrestore[findIndexItem]["file_value"] = 100;
              this.showrestore[findIndexItem]["file_status_pregress"] = 'ERR'
              check_error_round = true
            } else {
              this.showrestore[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletecancel');
              this.showrestore[findIndexItem]["file_value"] = 100;
              this.showrestore[findIndexItem]["file_status_pregress"] = 'ERR'
              check_cancel_error = true
            }
          }
        })
        console.log("GROUP ", chunck_group_array[i] )
        if(check_error_round || check_cancel_error){
          this.check_dis_button = false;
          this.check_show_cancel = false
          this.showrestore.filter((item) => item.file_value === 0 ).forEach((item) => {
            item.file_status =  this.$t('dialogmultidelete.deletecancel') 
            item.file_value = 100
            item.file_status_pregress = 'ERR'
          })
          break;
        }
        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('list-item')[0].children), 5)
        this.fn_scrollToItem(group_element_node_[i][group_element_node_[i].length - 1])
        console.log("GROUP ELEMTN ", group_element_node_)
        if(chunck_group_array.length - 1 === i){
          this.check_dis_button = false;
          this.check_show_cancel = false
        }

      }
    },

    //ลบไฟล์/โฟลเดอร์
    async fn_remove(payload, file_id) { // ไม่ได้ใช้งาน
      let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DELETE_MULTIPLES + "/api/delete_folder_file_trash_to_backup",
          data: payload,
          headers: { 
            Authorization: auth.code
           },
           onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          }
        });
        return new Promise((resolve, reject) => {
          console.log("Respond data ", result.data)
          try {
            if (result.data.status === "OK") {
              setTimeout(() => {
                    resolve({ status: "remove success", msg: result.data.message, code: "" });
                      }, 500);
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "remove denied", msg: ": " + err, code: "" });
                      }, 500);
          }
        });
    },

    async fn_remove_v2 (payload, file_id) {  // ใช้งานอยู่
      let auth = await gbfGenerate.generateToken();
      if(this.check_cancel_token) {
        this.cancelSource.cancel("Cancel All")
      }
      return await this.axios
      .post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_folder_file_trash_to_backup",
        payload,
        {
          headers: { Authorization: auth.code }, 
          cancelToken: this.cancelSource.token,
          onUploadProgress: (e) => {
            let findIndexItem = this.showrestore.findIndex((item) => item.file_id === file_id)
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[findIndexItem]["file_value"] = 95;
            } else {
              this.showrestore[findIndexItem]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          } 
        },
        
      )
      .then((response) => {
        let findIndexItem = this.showrestore.findIndex((item) => item.file_id === file_id)
        if(response.data.status === 'OK'){
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                status: 'OK',
                message: 'Success',
                id: file_id
              })
            },500)
          })
        } else {
          this.showrestore[findIndexItem].file_status = this.$t('dialogmultidelete.deletefail');
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                status: 'FILE/FOLDER ERROR',
                message: 'Error',
                id: file_id
              })
            },500)
          })
        }
      })
      .catch((err)=> {
        let findIndexItem = this.showrestore.findIndex((item) => item.file_id === file_id)
        if(this.axios.isCancel(err)){
          return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'CANCEL ERROR', 
                  message: 'Error',
                  id: file_id
                })
              },500)
        })
        }
        else {
        this.showrestore[findIndexItem].file_status = this.$t('dialogmultidelete.deletefail');
        return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: 'Error',
                  id: file_id
                })
              },500)
        })
        }
         
      })
    },

    async clearalldata () {
      this.$emit('closedialogreload')
      await this.timeout(1000)
      // this.checkAll = false
      this.check_show_progress = false
      this.check_cancel_token = false
      this.cancelSource = ""
    },

    async cancelDelete () {
      this.check_cancel_token =  true
      //this.cancelSource.cancel('Cacne')
    },


  },
  created() {},
};
</script>
<style>
#app_currentrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
  z-index: 9999;
}
</style>
